<template>
  <Dialog :visible="visible" :style="{ width: '50vw' }" :header="'Importation de fichiers : ' + item?.intitule" :modal="true" :closable="false" @hide="onClose" class="p-fluid">
    <div class="">
      <input v-show="false" type="file" ref="fileInputReference" @change="onDropFile" accept=".pdf,.png,.docx" />
      <div @dragover.prevent @drop.prevent>
        <div class="cnscl-uploader__zone" @drop="onDropFile">
          <h5 class="cnscl-uploader__title" style="vertical-align: middle">
            <strong><a href="#" @click="handleUploadDialog">Cliquez ici pour télécharger votre fichier</a></strong> ou Glissez le fichier à télécharger dans cette zone
            <br />
            <small class="cnscl-uploader__instuctions"><b style="font-size: 90%">Taille maximale d'un fichier : Aucune limite.</b></small>
          </h5>
        </div>
      </div>
      <br />
      <ProgressBar :value="transferStatus" v-show="transferStatus > 0 && transferStatus < 100" />
      <div class="file-list__list">
        <div class="file-list__item" v-for="attachment in item?.piecesJointes" :key="attachment.id">
          <div class="file-list__name"><img :src="getIconFromFileName(attachment.name)" alt="icon" width="20" /> {{ getFileNameFromUrl(attachment.name) }}</div>
          <div class="file-list__action">
            <a href="#" @click="onDownload($event, attachment.urlPath)">
              <strong class="pi pi-download"></strong>
            </a>
            <a href="#" class="rmv-btn" @click="onRemove($event, attachment.id)">
              <strong class="pi pi-times-circle"></strong>
            </a>
          </div>
        </div>
      </div>
      <hr v-show="item && item.piecesJointes?.length > 0" />
      <div class="file-list">
        <div class="file-list__list">
          <div class="file-list__item" v-for="(file, index) in files" :key="index">
            <div class="file-list__name"><img :src="getIconFromFileName(file.name)" :alt="file.name" width="25" /> &nbsp;&nbsp;&nbsp;{{ file.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="Annuler" icon="pi pi-times" class="p-button p-component p-button-outlined p-button-danger" @click="onClose" />
      <Button :loading="isLoading" :disabled="files.length === 0" class="p-button p-component p-button-outlined p-button-success" @click="onUpload">Envoyer les fichiers sur le serveur</Button>
    </template>
  </Dialog>
</template>

<script>
import FilesMixin from '../../mixins/file-view';

export default {
  mixins: [FilesMixin],
  name: 'files-uploader',
  props: ['item', 'transferStatus', 'visible', 'isLoading'],
  mounted() {
    this.files = [];
  },
  data: () => ({
    formData: new FormData(),
    files: [],
  }),
  methods: {
    onDropFile(event) {
      const source = event.dataTransfer !== undefined ? event.dataTransfer : event.currentTarget;
      const file = source.files[0];
      this.files.push(file);
      // this.formData.append("piecesJointes", file);
    },

    onRemove(event, attachmentId) {
      event.preventDefault();
      this.$emit('remove', attachmentId);
    },

    onUpload() {
      this.files.forEach((f) => {
        this.formData.append('piecesJointes', f);
      });
      this.$emit('upload', this.formData);
      this.files = [];
      this.formData = new FormData();
    },

    onClose() {
      this.files = [];
      this.$emit('close');
    },

    handleUploadDialog(event) {
      event.preventDefault();
      this.$refs.fileInputReference.click();
    },
  },
};
</script>

<style scoped>
.cnscl-file-viewer {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.viewer__content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}
.cnscl-uploader__zone {
  width: 100%;
  height: 100px;
  border: 2px dashed #e7e7e7;
  text-align: center;
  padding: 5%;
}

.file-list__item {
  padding: 10px;
  border-bottom: 2px dashed #e7e7e7;
  display: flex;
}
.file-list__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: auto;
  display: flex;
}
.file-list__name a {
  margin-left: auto;
}
.file-list__action {
  margin-left: auto;
}
.cnscl-uploader__actions {
  display: grid;
}
.cnscl-uploader__action-btn {
  text-align: center;
  width: 150px;
  margin: 10px auto;
}

.rmv-btn {
  border: none;
  padding: 5px;
  color: red;
}

.send-btn {
  padding: 10px;
  margin: 10px 0;
  background: #b6e2d5;
  border: 2px solid #4ab898;
}
</style>