const YearMixinSelector = {
  data: () => ({
    selectedWipYear: null,
  }),

  methods: {
    refreshStatsOnYear(e) {
      const target_copy = Object.assign({}, e);
      const year = target_copy.annee;
      const id = target_copy.id;
      if (this.isInAmiModule) this.fetchAll({ planId: id });
      if (this.isInAoModule) this.fetchAllAppelsOffres({ planId: id });
      // console.log({ id, isInAoModule: this.isInAoModule, isInAmiModule: this.isInAmiModule });
      // this.fetchAll({ planId: id });
      this.setSelectedPpmId(year);
      this.fetchStats({ queryParams: { annee: year } });
    },
  },

  watch: {},
};

export default YearMixinSelector;
