const PpmActionsMixin = {
  methods: {
    canCreateOrTransmitOrDelete(entity) {
      return entity.etat === 'CREE' || entity.etat === 'VALIDE' || entity.etat === 'RENVOYE';
    },

    canModify(entity) {
      return this.canCreateOrTransmitOrDelete(entity);
    },

    canDelete(entity) {
      return this.canCreateOrTransmitOrDelete(entity);
    },

    canTransmit(entity) {
      return this.canCreateOrTransmitOrDelete(entity);
    },

    canSeeRejection(entity) {
      return entity.etat === 'RENVOYE';
    },

    canAttribute(entity) {
      return entity.etat === 'VALIDE' || entity.etat === 'ATTRIBUE';
    },

    canClose(entity) {
      return entity.etat === 'ATTRIBUE';
    },

    // TODO: Create bew constant FeatureActionsMixin.
    // TODO: methods TRIAL, ECHU Can see features (Status Echu).
  },
};

export default PpmActionsMixin;
